import axiosClient from 'utils/axiosClient';
import api from 'api';
const getPermissions = async (data: any) => {
	const response = await axiosClient.get(api.PERMISSIONS.PERMISSIONS, {params: data});
	return response.data;
};

const getPermissionByID = async (id: number) => {
	const response = await axiosClient.get(api.PERMISSIONS.PERMISSIONS + `/${id}`);
	return response.data;
};

// const createPrivilege = async (data: any) => {
// 	const response = await axiosClient.post(api.PERMISSIONS.PERMISSIONS, data);
// 	return response.data;
// };

const updatePermissions = async (id: number, data: any) => {
	const response = await axiosClient.patch(api.PERMISSIONS.PERMISSIONS + `/${id}`, data);
	return response.data;
};

const service = {
	getPermissions,
	getPermissionByID,
	// createPrivilege,
	updatePermissions
};

export default service;
