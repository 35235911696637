import axiosClient from 'utils/axiosClient';
import api from 'api';
import {formData} from 'utils/helpers/formData';

const getMenu = async () => {
	const response = await axiosClient.get(api.SETTINGS.MENU);
	return response.data;
};

const getCharts = async () => {
	const response = await axiosClient.get(api.SETTINGS.GET_CHARTS);
	return response.data;
};

const getDictionary = async (dict: string, params?: any) => {
	let newParams = {...params};
	delete newParams.page;
	delete newParams.take;

	const response = await axiosClient.get(api.SETTINGS.DICT + `/${dict}`, {params: newParams});
	const newData = response.data.data;

	return {data: newData};
};

const deleteDictionaryItem = async (dict: string, id: number) => {
	const response = await axiosClient.delete(api.SETTINGS.DICT + `/${dict}/${id}`);
	return response.data;
};

const addDictionaryItem = async (dict: string, data: any) => {
	const response = await axiosClient.post(api.SETTINGS.DICT + `/${dict}`, data);
	return response.data;
};

const updateDictionaryItem = async (dict: string, data: any, id: number) => {
	const response = await axiosClient.patch(api.SETTINGS.DICT + `/${dict}/${id}`, data);
	return response.data;
};

interface DictionaryType {
	id: number;
	hy: string;
	en: string;
	ru: string;
	status: number;
	isTrigger: 0 | 1;
	creationDate: string;
	lastModifiedDate: string;
}

const fetchOptions = async (dictionary: string, params?: any): Promise<DictionaryType[]> => {
	const response = await axiosClient.get(api.SETTINGS.DICT + `/${dictionary}`, {params});

	const newData = response.data.data;

	return params?.sortField
		? newData
		: newData.find((el: any) => el.hy)
		? newData.sort((a: {id: number}, b: {id: number}) => a.id > b.id)
		: newData;
};

const getByCustomPath = async (path: string, fetchParams: any): Promise<any> => {
	const response = await axiosClient.get(path, {params: fetchParams});
	return response.data.data || response.data;
};

const updateProfile = async (data: any) => {
	const response = await axiosClient.patch(api.SETTINGS.UPDATE_PROFILE, data);
	return response.data;
};

const updatePassword = async (data: any) => {
	const response = await axiosClient.patch(api.SETTINGS.UPDATE_PASSWORD, data);
	return response.data;
};

const getMailConfigs = async () => {
	const response = await axiosClient.get(api.SETTINGS.MAIL_CONFIGS);
	return response.data;
};

const updateMailConfigs = async (data: any) => {
	const response = await axiosClient.patch(api.SETTINGS.MAIL_CONFIGS, data);
	return response.data;
};

/* Guide */

const getGuide = async (data?: any) => {
	const response = await axiosClient.get(api.SETTINGS.GUIDE, {params: data});
	return response.data;
};

const getGuideByID = async (id: number, data?: any) => {
	const response = await axiosClient.get(api.SETTINGS.GUIDE + `/${id}`, {params: data});
	return response.data;
};

const createGuide = async (data?: any) => {
	const response = await axiosClient.post(api.SETTINGS.GUIDE, formData(data));
	return response.data;
};

const updateGuide = async (id: number, data?: any) => {
	const response = await axiosClient.patch(api.SETTINGS.GUIDE + `/${id}`, formData(data));
	return response.data;
};

const deleteGuide = async (ids: number[]) => {
	const response = await axiosClient.delete(api.SETTINGS.GUIDE + `/${ids}`);
	return response.data;
};

const service = {
	getMenu,
	getDictionary,
	deleteDictionaryItem,
	addDictionaryItem,
	updateDictionaryItem,
	fetchOptions,
	getByCustomPath,
	updateProfile,
	updatePassword,
	getCharts,
	getMailConfigs,
	updateMailConfigs,
	getGuide,
	getGuideByID,
	updateGuide,
	createGuide,
	deleteGuide
};

export default service;
