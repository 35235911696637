import axiosClient from 'utils/axiosClient';
import api from 'api';
import {formData} from 'utils/helpers/formData';

const getDrivers = async (params?: any) => {
	const response = await axiosClient.get(api.DRIVERS.DRIVERS, {params});
	return response.data;
};
const getDriverByID = async (id: number) => {
	const response = await axiosClient.get(api.DRIVERS.DRIVER_BY_ID + id);
	return response.data;
};

const createDriver = async (data: any) => {
	const response = await axiosClient.post(api.DRIVERS.DRIVERS, formData(data));
	return response.data;
};

const updateDriver = async (data: any, id: number) => {
	const response = await axiosClient.patch(api.DRIVERS.DRIVER_BY_ID + id, formData(data));
	return response.data;
};

const deleteDriver = async (ids: number[]) => {
	const response = await axiosClient.delete(api.DRIVERS.DRIVER_BY_ID, {data: {ids}});
	return response.data;
};

const service = {
	getDrivers,
	getDriverByID,
	createDriver,
	updateDriver,
	deleteDriver
};

export default service;
