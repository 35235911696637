import axiosClient from 'utils/axiosClient';
import api from 'api';
import {formData} from 'utils/helpers/formData';

const getBuses = async (params?: any) => {
	const response = await axiosClient.get(api.BUSES.BUSES, {params});
	return response.data;
};

const getBusById = async (id: any) => {
	const response = await axiosClient.get(api.BUSES.BUSES + '/' + id);
	return response.data;
};

const createBus = async (data: any) => {
	const response = await axiosClient.post(api.BUSES.BUSES, data);
	return response.data;
};

const deleteBus = async (data: any) => {
	const response = await axiosClient.delete(api.BUSES.BUSES, {data});
	return response.data;
};

const updateBus = async (data: any, id: any) => {
	const response = await axiosClient.patch(api.BUSES.BUSES + '/' + id, data);
	return response.data;
};

const service = {
	getBuses,
	getBusById,
	createBus,
	deleteBus,
	updateBus
};

export default service;
