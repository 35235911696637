import AUTH from './auth.service';
import SETTINGS from './settings.service';
import USERS from './users.service';
import DRIVERS from './partners.drivers.service';
import EXCEL from './excel.service';
import NOTIFICATIONS from './notifications.service';
import PARTNERS from './partners.service';
import PERMISSIONS from './permissions.service';
import ROUTES from './routes.service';
import CARRIERS from './carriers.service';
import TICKETS from './tickets.service';
import FAQ from './faq.service';
import NEWS from './news.service';
import BANNERS from './banners.service';
import TRANSACTIONS from './transactions.service';
import VALIDATORS from './validators.service';
import COMPLAINTS from './complaints.service';
import BUSES from './buses.service';
import BUSSTOPS from './busStops.service';
import CONTACTS from './contacts.service';

const SERVICES = {
	AUTH,
	SETTINGS,
	USERS,
	EXCEL,
	NOTIFICATIONS,
	PARTNERS,
	DRIVERS,
	PERMISSIONS,
	ROUTES,
	CARRIERS,
	TICKETS,
	FAQ,
	NEWS,
	BANNERS,
	TRANSACTIONS,
	VALIDATORS,
	COMPLAINTS,
	BUSES,
	BUSSTOPS,
	CONTACTS
};

export default SERVICES;
