import axiosClient from 'utils/axiosClient';
import api from 'api';

const getUsers = async (params: any) => {
	const response = await axiosClient.get(api.USERS.USERS, {params});
	return response.data;
};
const getUsersByID = async (id: number) => {
	const response = await axiosClient.get(api.USERS.USERS + '/' + id);
	return response.data;
};

const createUser = async (data: any) => {
	let changedData = {...data /*phoneNumber: '+' + data.phoneNumber*/};
	const response = await axiosClient.post(api.USERS.USERS, changedData);
	return response.data;
};

const updateUser = async (data: any, id: number) => {
	const response = await axiosClient.patch(api.USERS.USERS + '/' + id, data);
	return response.data;
};

const updateUserPassword = async (data: any, id: number) => {
	const response = await axiosClient.patch(api.USERS.USERS + '/' + id + '/changePassword', data);
	return response.data;
};

const deleteUser = async (data: any) => {
	const response = await axiosClient.delete(api.USERS.USERS, {data});
	return response.data;
};

// const updateUser = async (data: any, id: number) => {
// 	const response = await axiosClient.patch(api.USERS.USERS + '/' + id, data);
// 	return response.data;
// };

const getProfile = async () => {
	const response = await axiosClient.get(api.USERS.PROFILE);
	return response.data;
};

const service = {
	getUsers,
	createUser,
	updateUser,
	getUsersByID,
	updateUserPassword,
	getProfile,
	deleteUser
};

export default service;
