import axiosClient from 'utils/axiosClient';
import api from 'api';
import {formData} from 'utils/helpers/formData';

const getBanners = async (params?: any) => {
	const response = await axiosClient.get(api.BANNERS.BANNERS, {params});
	return response.data;
};

const getBannerById = async (id: any) => {
	const response = await axiosClient.get(api.BANNERS.BANNERS + '/' + id);
	return response.data;
};

const createBanner = async (data: any) => {
	const response = await axiosClient.post(api.BANNERS.BANNERS, formData(data));
	return response.data;
};

const deleteBanner = async (data: any) => {
	const response = await axiosClient.delete(api.BANNERS.BANNERS, {data});
	return response.data;
};

const updateBanner = async (data: any, id: any) => {
	const response = await axiosClient.patch(api.BANNERS.BANNERS + '/' + id, formData(data));
	return response.data;
};

const service = {
	getBanners,
	getBannerById,
	createBanner,
	deleteBanner,
	updateBanner
};

export default service;
