import {useState} from 'react';

export function useLocalStorage<T>(
	key: string,
	initialValue: T,
	disableSave?: boolean
): [T, (value: (val: T) => T) => void] {
	// State to store our value
	// Pass initial state function to useState so logic is only executed once
	const [storedValue, setStoredValue] = useState(() => {
		if (typeof window === 'undefined') {
			return initialValue;
		}
		try {
			// Get from local storage by key
			const item = window.localStorage.getItem(key);
			if (item === 'undefined') return initialValue;
			if (item === 'null') return initialValue;
			// Parse stored json or if none return initialValue
			let data = JSON.parse(item || '{}');

			if (
				key.startsWith('columns/') &&
				Array.isArray(data) &&
				data.some((el: any) => el.dataIndex) &&
				Array.isArray(initialValue)
			) {
				data = data.map((el: any) => ({
					...el,
					title: initialValue.find((elem: any) => el.dataIndex === elem.dataIndex)?.title,
					shortName:
						initialValue.find((elem: any) => el.dataIndex === elem.dataIndex)?.shortName || null
				}));
			}
			return item ? data : initialValue;
		} catch (error) {
			// If error also return initialValue
			console.log(error);
			return initialValue;
		}
	});
	// Return a wrapped version of useState's setter function that ...
	// ... persists the new value to localStorage.
	const setValue = (value: ((arg0: T) => T) | T) => {
		try {
			// Allow value to be a function so we have same API as useState
			const valueToStore = value instanceof Function ? value(storedValue) : value;
			// Save state
			setStoredValue(valueToStore);
			// Save to local storage
			if (typeof window !== 'undefined' && !disableSave) {
				window.localStorage.setItem(key, JSON.stringify(valueToStore));
			}
		} catch (error) {
			// A more advanced implementation would handle the error case
			console.log(error);
		}
	};
	return disableSave ? [initialValue, setValue] : [storedValue, setValue];
}
