import {Layout} from 'antd';
import {useTranslation} from 'react-i18next';
import packageJSON from '../../../../package.json';
const {Footer} = Layout;

const AppFooter = () => {
	const {t} = useTranslation();

	return (
		<Footer
			style={{
				display: 'flex',
				justifyContent: 'space-between',
				padding: '8px 40px',
				textAlign: 'center',
				background: '#fff'
			}}>
			<div>v{packageJSON.version}</div>
			<div>
				© {t('footerText', {date: new Date().getFullYear()})}
				<a href="https://smartsoft.am/">SMARTSOFT</a>
				{t('footerFrom')}
			</div>
		</Footer>
	);
};

export default AppFooter;
