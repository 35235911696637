import axiosClient from 'utils/axiosClient';
import api from 'api';
import {formData} from 'utils/helpers/formData';

const getBusStops = async (params?: any) => {
	const response = await axiosClient.get(api.BUSSTOPS.BUSSTOPS, {params});
	return response.data;
};

const getBusStopsId = async (id: any) => {
	const response = await axiosClient.get(api.BUSSTOPS.BUSSTOPS + '/' + id);
	return response.data;
};

const createBusStops = async (data: any) => {
	const response = await axiosClient.post(api.BUSSTOPS.BUSSTOPS, data);
	return response.data;
};

const deleteBusStops = async (data: any) => {
	const response = await axiosClient.delete(api.BUSSTOPS.BUSSTOPS, {data});
	return response.data;
};


const updateBusStops = async (data: any, id: any) => {
	const response = await axiosClient.patch(api.BUSSTOPS.BUSSTOPS + '/' + id, data);
	return response.data;
};

const service = {
	getBusStops,
	getBusStopsId,
	createBusStops,
	deleteBusStops,
	updateBusStops
};

export default service;
