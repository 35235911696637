import axiosClient from 'utils/axiosClient';
import api from 'api';

const getContacts = async (params?: any) => {
	const response = await axiosClient.get(api.CONTACTS.CONTACTS, {params});
	return response.data;
};

const getContactById = async (id: any) => {
	const response = await axiosClient.get(api.CONTACTS.CONTACTS + '/' + id);
	return response.data;
};

const createContact = async (data: any) => {
	const response = await axiosClient.post(api.CONTACTS.CONTACTS, data);
	return response.data;
};

const deleteContacts = async (data: any) => {
	const response = await axiosClient.delete(api.CONTACTS.CONTACTS, {data});
	return response.data;
};

const updateContact = async (data: any, id: any) => {
	const response = await axiosClient.patch(api.CONTACTS.CONTACTS + '/' + id, data);
	return response.data;
};

const service = {
	getContacts,
	getContactById,
	createContact,
	deleteContacts,
	updateContact
};

export default service;
