import axiosClient from 'utils/axiosClient';
import api from 'api';
import {formData} from 'utils/helpers/formData';

const getNews = async (params: any) => {
	const response = await axiosClient.get(api.NEWS.NEWS, {params});
	return response.data;
};

const getNewsById = async (id: any) => {
	const response = await axiosClient.get(api.NEWS.NEWS + '/' + id);
	return response.data;
};

const createNews = async (data: any) => {
	const response = await axiosClient.post(api.NEWS.NEWS, formData(data));
	return response.data;
};

const deleteNews = async (data: any) => {
	const response = await axiosClient.delete(api.NEWS.NEWS, {data});
	return response.data;
};

const updateNews = async (data: any, id: any) => {
	const response = await axiosClient.patch(api.NEWS.NEWS + '/' + id, formData(data));
	return response.data;
};

const service = {
	getNews,
	getNewsById,
	createNews,
	deleteNews,
	updateNews
};

export default service;
