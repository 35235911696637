import axios from 'axios';
import i18next from 'i18next';
import Toast from 'components/Toast';

const exportExcel = (
	filters: any,
	ids: number[],
	api: string,
	fileName: string,
	type: 'xlsx' | 'pdf' = 'xlsx'
) => {
	axios
		.get(process.env.REACT_APP_API_URL + api, {
			responseType: 'blob',
			headers: {
				Authorization: 'Bearer ' + localStorage.getItem('token'),
				'Accept-Language': i18next.language
			},
			params: {...filters, IDs: ids.length ? ids.join() : undefined}
		})
		.then(response => {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `${fileName}.${type}`);
			document.body.appendChild(link);
			link.click();
		})
		.catch(err => {
			err.response.data.text().then((res: string) => Toast.error(JSON.parse(res).message));
		});
};
const exportExcelWithPromis = (
	filters: any,
	ids: number[],
	api: string,
	fileName: string,
	type: 'xlsx' | 'pdf' = 'xlsx'
) => {
	return axios
		.get(process.env.REACT_APP_API_URL + api, {
			responseType: 'blob',
			headers: {
				Authorization: 'Bearer ' + localStorage.getItem('token'),
				'Accept-Language': i18next.language
			},
			params: {...filters, IDs: ids.length ? ids.join() : undefined}
		})
		.then(response => {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `${fileName}.${type}`);
			document.body.appendChild(link);
			link.click();
		})
		.catch(err => {
			err.response.data.text().then((res: string) => Toast.error(JSON.parse(res).message));
		});
};

const exportCandidatesExcel = (filters: any, ids: number[]) => {
	exportExcel(filters, ids, '', 'Candidates');
};

const exportAcademicNewsletter = (id: number) => {
	return exportExcelWithPromis({}, [], '', 'Academic bulletin', 'pdf');
};

const service = {
	exportCandidatesExcel,
	exportAcademicNewsletter
};

export default service;
