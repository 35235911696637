import axiosClient from 'utils/axiosClient';
import api from 'api';
import {formData} from 'utils/helpers/formData';

const getComplaints = async (params?: any) => {
	const response = await axiosClient.get(api.COMPLAINTS.COMPLAINTS, {params});
	return response.data;
};

const getComplaintById = async (id: any) => {
	const response = await axiosClient.get(api.COMPLAINTS.COMPLAINTS + '/' + id);
	return response.data;
};

const createComplaint = async (data: any) => {
	const response = await axiosClient.post(api.COMPLAINTS.COMPLAINTS, formData(data));
	return response.data;
};

const deleteComplaint = async (data: any) => {
	const response = await axiosClient.delete(api.COMPLAINTS.COMPLAINTS, {data});
	return response.data;
};

const updateComplaint = async (data: any, id: any) => {
	const response = await axiosClient.patch(api.COMPLAINTS.COMPLAINTS + '/' + id, formData(data));
	return response.data;
};

const service = {
	getComplaints,
	getComplaintById,
	createComplaint,
	deleteComplaint,
	updateComplaint
};

export default service;
