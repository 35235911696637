import axiosClient from 'utils/axiosClient';
import api from 'api';
import {formData} from 'utils/helpers/formData';

const getRoutes = async (params?: any) => {
	const response = await axiosClient.get(api.ROUTES.ROUTES, {params});
	return response.data;
};
const getRoutesByID = async (id: number) => {
	const response = await axiosClient.get(api.ROUTES.ROUTES + `/${id}`);
	return response.data;
};

const createRoute = async (data: any) => {
	const response = await axiosClient.post(api.ROUTES.ROUTES, data);
	return response.data;
};
const attachBusStopsToRoute = async (id: number, data: any) => {
	const response = await axiosClient.post(api.ROUTES.ROUTES + `/${id}/bus-stops`, data);
	return response.data;
};
const updateAttachedBusStopToRoute = async (routeId: number, busStopId: number, data: any) => {
	const response = await axiosClient.patch(api.ROUTES.ROUTES + `/${routeId}/bus-stops/${busStopId}`, data);
	return response.data;
};
const detachBusStopsToRoute = async (id: number, data: any) => {
	const response = await axiosClient.delete(api.ROUTES.ROUTES + `/${id}/bus-stops`, {data});
	return response.data;
};

const deleteRoute = async (data: any) => {
	const response = await axiosClient.delete(api.ROUTES.ROUTES, {data});
	return response.data;
};

const updateRoute = async (data: any, id: any) => {
	const response = await axiosClient.patch(api.ROUTES.ROUTES + '/' + id, formData(data));
	return response.data;
};

const service = {
	getRoutes,
	createRoute,
	getRoutesByID,
	deleteRoute,
	updateRoute,
	attachBusStopsToRoute,
	updateAttachedBusStopToRoute,
	detachBusStopsToRoute
};

export default service;
