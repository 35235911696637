import {Dispatch, ReactNode, createContext, useReducer} from 'react';

export interface SettingsStateProps {
	menuItems: MenuItem[] | any;
}

interface InitContextProps {
	state: SettingsStateProps;
	dispatch: Dispatch<Action>;
}

export interface MenuItem {
	creationDate: string;
	delete: 0 | 1;
	get: 0 | 1;
	id: number;
	itemOrderNum: number;
	itemParentID: number;
	lastModifiedDate: string | null;
	page: string;
	pageId: number;
	post: 0 | 1;
	privilegieId: number;
	privilegies_name: string;
	patch: 0 | 1;
	children: MenuItem[] | [];
	url: string;
}

type Action = {type: 'GET_MENU'; payload: MenuItem[]};

export const SettingsContext = createContext({} as InitContextProps);

const reducer = (state: SettingsStateProps, action: Action) => {
	switch (action.type) {
		case 'GET_MENU':
			return {
				...state,
				menuItems: action.payload
			};
		default:
			return state;
	}
};
const initialState = {
	menuItems: []
};
const SettingsProvider = ({children}: {children: ReactNode}) => {
	const [state, dispatch] = useReducer(reducer, initialState);
	const value = {state, dispatch};

	// const prevFaviconPath = useRef<string>('');

	// useEffect(() => {
	// 	const faviconPath = state.filesConfigs.find(file => file.typeID === 5);
	// 	if (faviconPath !== undefined && faviconPath.path !== prevFaviconPath.current) {
	// 		prevFaviconPath.current = faviconPath.path;
	// 		let link = document.querySelector("link[rel~='icon']");
	// 		if (!link) {
	// 			link = document.createElement('link');
	// 			(link as any).rel = 'icon';
	// 			document.getElementsByTagName('head')[0].appendChild(link);
	// 		}
	// 		(link as any).href = process.env.REACT_APP_API_URL + '/' + faviconPath.path;
	// 	} else if (faviconPath === undefined) {
	// 		prevFaviconPath.current = '';
	// 		let link = document.querySelector("link[rel~='icon']");
	// 		if (!link) {
	// 			link = document.createElement('link');
	// 			(link as any).rel = 'icon';
	// 			document.getElementsByTagName('head')[0].appendChild(link);
	// 		}
	// 		(link as any).href = '../assets/eUniversityLogo.png';
	// 	}
	// 	document.title =
	// 		state.appConfigs[`universityName_${localStorage.getItem('language') as 'hy' | 'en' | 'ru'}`] ||
	// 		state.appConfigs.universityName_hy ||
	// 		'eUniversity';
	// }, [state]);
	return <SettingsContext.Provider value={value}>{children}</SettingsContext.Provider>;
};

export default SettingsProvider;
