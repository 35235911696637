import axiosClient from 'utils/axiosClient';
import api from 'api';

const getPartners = async (params?: any) => {
	const response = await axiosClient.get(api.PARTNERS.PARTNERS, {params});
	return response.data;
};

const getPartnerById = async (id: any) => {
	const response = await axiosClient.get(api.PARTNERS.PARTNERS + '/' + id);
	return response.data;
};

const getLegalForms = async () => {
	const response = await axiosClient.get(api.PARTNERS.PARTNERS);
	return response.data;
};

const createPartner = async (data: any) => {
	const response = await axiosClient.post(api.PARTNERS.PARTNERS, data);
	return response.data;
};

const deletePartner = async (data: any) => {
	const response = await axiosClient.delete(api.PARTNERS.PARTNERS, {data});
	return response.data;
};

const updatePartner = async (data: any, id: any) => {
	const response = await axiosClient.patch(api.PARTNERS.PARTNERS + '/' + id, data);
	return response.data;
};

const service = {
	getPartners,
	getLegalForms,
	createPartner,
	deletePartner,
	updatePartner,
	getPartnerById
};

export default service;
