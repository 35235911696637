import axiosClient from 'utils/axiosClient';
import api from 'api';
import {formData} from 'utils/helpers/formData';

const getTransactions = async (params: any) => {
	const response = await axiosClient.get(api.TRANSACTIONS.TRANSACTIONS, {params});
	return response.data;
};

const getTransactionById = async (id: any) => {
	const response = await axiosClient.get(api.TRANSACTIONS.TRANSACTIONS + '/' + id);
	return response.data;
};

const createTransaction = async (data: any) => {
	const response = await axiosClient.post(api.TRANSACTIONS.TRANSACTIONS, formData(data));
	return response.data;
};

const deleteTransactions = async (data: any) => {
	const response = await axiosClient.delete(api.TRANSACTIONS.TRANSACTIONS, {data});
	return response.data;
};

const updateTransaction = async (data: any, id: any) => {
	const response = await axiosClient.patch(api.TRANSACTIONS.TRANSACTIONS + '/' + id, data);
	return response.data;
};

const service = {
	getTransactions,
	getTransactionById,
	createTransaction,
	deleteTransactions,
	updateTransaction
};

export default service;
